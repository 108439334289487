import { useLocation } from '@reach/router';
import { ConfigProvider, message } from 'antd';
import { Link, navigate } from 'gatsby';
import { get, includes, indexOf, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from '../../../locales/useTranslation';
import authenticateUser from '../../../newApi/authenticate';
import { clearModal } from '../../../redux/actions/app-actions';
import { logoutSuccessful, updateLoginTime } from '../../../redux/actions/user-actions';
import { routes } from '../../../route';
import { getLocalForAntd } from '../../../utilities/profile';
import { navBarItems } from '../config';

// markup
const Layout = (props) => {
  const location = useLocation();
  const currentPath =
    location.pathname[location.pathname.length - 1] === "/" &&
    location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname;
  const accessKey = get(props.user, "accessKey");
  const [loggedIn, setLoggedIn] = useState(false);
  // const [skipAlert, setSkipAlert] = useState(false);
  const { t, languages } = useTranslation();

  message.config({
    top: 10,
    duration: 1,
    maxCount: 1,
  });

  useEffect(() => {
    if (isEmpty(accessKey)) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [accessKey]);

  useEffect(() => {
    if (!includes(get(location, "pathname"),routes.login.to())) {
      // console.log("here not login",get(location, "pathname"))
      if (
        includes(get(location, "pathname"), "email_verification") ||
        includes(get(location, "pathname"), "reset_password") ||
        includes(get(location, "pathname"), "register")
      ) {
        //skip authorization
      } else {
        // console.log(props.user)
        authenticateUser({
          profileId: get(props.user, "user.profile._id"),
          timestamp: get(props.user, "timestamp"),
          accessKey: get(props.user, "accessKey"),
        })
          .then((res) => {})
          .catch(function (error) {
            console.log(error);
            //message.error('Session Expired. Please Login Again');

            window.location.href = routes.myits.to();
          });
      }
    }
    // if (
    //   includes(get(location, "pathname"), "login") ||
    //   includes(get(location, "pathname"), "register") ||
    //   includes(get(location, "pathname"), "forgot_password")
    // ) {
    //   setSkipAlert(true);
    // }
  }, []);

  // useEffect(() => {
  //   let user = get(props, "user");
  //   if (loggedIn) {
  //     let loginTime = get(user, "loginTime");
  //     if (loginTime === null) {
  //       //logount
  //       logout();
  //     } else {
  //       // loginTime = new Date(loginTime);
  //       loginTime = moment(new Date(loginTime));
  //       let expiredTime = loginTime.add(30, "minutes").toDate().getTime();
  //       let dateNow = new Date();
  //       if (dateNow.getTime() > expiredTime) {
  //         message.error(t("loginAgain"));
  //         logout();
  //       } else {
  //         props.updateLoginTime();
  //       }
  //     }
  //   }
  // }, [loggedIn]);

  function logout() {
    props.clearModal();
    props.logoutSuccessful();
    navigate(routes.login.to());
    // window.location.assign("https://myits.co/");
    // message.success("已登出! Logged out!");
    // if (typeof window !== `undefined`) {
    //   navigate(routes.home.to());
    // }
  }

  return (
    <div className="flex justify-center">
      <div
        className=" min-h-screen relative theme-bg-color"
        style={{ minWidth: 300, maxWidth: 667 }}
      >
        <ConfigProvider locale={getLocalForAntd(languages)}>
          <div className="" style={{ minHeight: "105vh" }}>
            {props.children}
            {/* <NotificationModal /> */}
            {/* {skipAlert === false && loggedIn === true && <AlertModal />} */}
          </div>
        </ConfigProvider>
        <div
          className="z-20 rounded-3xl sticky bottom-0 right-0 left-0 px-1 py-5 w-screen"
          style={{ maxWidth: 667, backgroundColor: "#474747" }}
        >
          <div className="flex justify-around items-center">
            {loggedIn &&
              map(navBarItems, (item, index) => {
                const isSamePath =
                  currentPath === item.path ||
                  indexOf(item.childrenRoutes, currentPath) !== -1;

                return (
                  <span
                    className="inline-block mx-0.5 "
                    key={`navBar-${index}`}
                  >
                    <Link to={item.path}>
                      <div className="flex justify-center items-center ">
                        {isSamePath ? item.activeIcon : item.icon}
                      </div>
                      {/* <div className={`text-sm ${isSamePath ? 'main-color' : 'text-white'}`}>
                      {item.text}
                    </div> */}
                    </Link>
                  </span>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = {
  logoutSuccessful,
  clearModal,
  updateLoginTime,
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
