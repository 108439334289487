// const mainJson = require("./Main.json");
// const loginJson = require("./Login.json");
// const commonJson = require("./Common.json");
// const dashboardJson = require("./Dashboard.json");
// const userJson = require("./User.json");
// const assetJson = require("./Asset.json");
// const rewardJson = require("./Reward.json");
// const highFrequencyJson = require("./HighFrequency.json");

import mainJson from "./Main.json";
import loginJson from "./Login.json";
import commonJson from "./Common.json";
import homePageJson from "./HomePage.json";
import apiJson from "./Api.json";
import assetJson from "./Asset.json";
import highFrequencyJson from "./HighFrequency.json";
import newAndVideo from "./NewsAndVideo.json";
import pairingJson from "./Pairing.json";
import pairingErrorJson from "./PairingError.json";
import profileJson from "./Profile.json";

import { forEach, mapKeys } from "lodash";
import { sourceKey } from "../config";

const objectsToRemap = [
  {
    value: mainJson,
    key: "",
  },
  {
    value: loginJson,
    key: "",
  },
  {
    value: commonJson,
    key: "",
  },
  {
    value: homePageJson,
    key: sourceKey.home,
  },
  {
    value: apiJson,
    key: sourceKey.api,
  },
  // {
  //     value: userJson,
  //     key: sourceKey.user,
  // },
  {
    value: assetJson,
    key: sourceKey.asset,
  },
  {
    value: profileJson,
    key: sourceKey.profile,
  },
  // {
  //     value: rewardJson,
  //     key: sourceKey.reward,
  // },
  {
    value: highFrequencyJson,
    key: sourceKey.highFrequency,
  },
  {
    value: pairingJson,
    key: sourceKey.pairing,
  },
  {
    value: pairingErrorJson,
    key: sourceKey.pairing,
  },
  // {
  //     value: enquiryJson,
  //     key: sourceKey.enquiry,
  // },
  // {
  //     value: emailJson,
  //     key: sourceKey.email,
  // },
  // {
  //     value: robotSimulateJson,
  //     key: sourceKey.robotSimulate,
  // },
  // {
  //     value: internalTradingJson,
  //     key: sourceKey.internalTrading,
  // },
  {
    value: newAndVideo,
    key: sourceKey.newAndVideo,
  },
  // {
  //     value: admin,
  //     key: sourceKey.admin,
  // },
];

let remappedObjects = {};
forEach(objectsToRemap, (item) => {
  let newObj = item.value;
  if (item.key) {
    newObj = mapKeys(item.value, (value, key) => {
      return `${item.key}.${key}`;
    });
  }

  remappedObjects = {
    ...remappedObjects,
    ...newObj,
  };
});

const allEnJson = {
  ...remappedObjects,
};
console.log(allEnJson);

export default allEnJson;
