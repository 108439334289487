import Enlocale from "antd/es/locale/en_GB";
import Indolocale from "antd/es/locale/id_ID";
import Cnslocale from "antd/es/locale/zh_CN";
import Cnlocale from "antd/es/locale/zh_TW";


export const languages = [
  {
    value: "en",
    title: "English",
  },
  {
    value: "cn",
    title: "中文（繁體）",
  },
  {
    value: "cns",
    title: "中文（简体）",
  },
  {
    value: "indo",
    title: "Indonesia",
  },
];

export function getLocalForAntd(lang) {
  switch (lang) {
    case "en":
      return Enlocale;
    case "cns":
      return Cnslocale;
    case "cn":
      return Cnlocale;
    case "indo":
      return Indolocale;
    default:
      return Enlocale;
  }
}
