import React from "react";

import { WalletOutlined } from "@ant-design/icons";
import { performanceActiveNavImg, performanceNavImg, robotActiveNavImg, robotNavImg } from "../../images";
import { routes } from "../../route";
import { mainColor } from "../../styles/config";

export const navBarItems = [
  // {
  //   icon: <img src={homenavIconImg} width={25} height={25} />,
  //   activeIcon: <img src={homeActiveImg} width={25} height={25} />,
  //   text: "Home",
  //   value: "home",
  //   path: routes.home.to(),
  // },
  {
    icon: <img src={performanceNavImg} width={25} height={25} />,
    activeIcon: <img src={performanceActiveNavImg} width={25} height={25} />,
    text: "Home",
    value: "home",
    path: routes.home.to(),
    // text: "Performance",
    // value: "performance",
    // path: routes.explore.to(),
  },
  {
    icon: <img src={robotNavImg} width={25} height={25} />,
    activeIcon: <img src={robotActiveNavImg} width={25} height={25} />,
    text: "highfrequency",
    value: "high_frequency",
    path: routes.highFrequency.to(),
  },

  {
    icon: <WalletOutlined style={{ color: "#CCCCCC", fontSize: "23px" }} />,
    activeIcon: (
      <WalletOutlined style={{ color: mainColor, fontSize: "23px" }} />
    ),
    text: "Simulator",
    value: "simulator",
    path: routes.transaction.to(),
  },
  // {
  //   icon: <img src={exploreIconImg} width={25} height={25} />,
  //   activeIcon: <img src={exploreActiveImg} width={25} height={25} />,
  //   text: "Explore",
  //   value: "explore",
  //   path: routes.rewardExplore.to(),
  // },
  // {
  //     icon: <FileTextOutlined style={{ color: 'white', fontSize: '25px' }} />,
  //     activeIcon: <FileTextOutlined style={{ color: mainColor, fontSize: '25px' }} />,
  //     text: 'Record',
  //     value: 'record',
  //     path: routes.billing.to(),
  // },
  // {
  //   // icon: <WalletOutlined style={{ color: "white", fontSize: "25px" }} />,
  //   icon: <img src={capImg} width={25} height={25} />,
  //   activeIcon: <img src={capActiveImg} width={25} height={25} />,
  //   text: "Billing",
  //   value: "billing",
  //   path: routes.incomeCapHome.to(),
  // },
  // {
  //   icon: <img src={profilenavIconImg} width={22} height={22} />,
  //   activeIcon: <img src={profileActiveImg} width={22} height={22} />,
  //   text: "Profile",
  //   value: "profile",
  //   path: routes.profile.to(),
  //   childrenRoutes: [routes.setting.to()],
  // },
];

